import { axios } from "@flytio/shared-library";
import { overrideAxios } from "@/services/axios-override";

export async function getAllBrands() {
  let response = await overrideAxios(() =>
    axios.get("/brands")
  );

  return response.data;
}

export async function mergeBrands(sources, destination) {
  let destinationSlug = destination.slug;
  let sourceSlugs = [];

  sources.forEach((item) => {
    if (item.slug) {
      sourceSlugs.push(item.slug);
    }
  });

  return await overrideAxios(() =>
    axios.post(`/brands/${destinationSlug}/merge`, {
      brands_to_merge: sourceSlugs,
    })
  );
}

export async function renameBrand(brandSlug, newName) {
  return await overrideAxios(() =>
    axios.post(`/brands/${brandSlug}/rename`, {
      name: newName
    })
  );
}