<template>
  <div>
    <v-alert
      class="mt-2"
      color="green"
      type="success"
      v-if="hasSuccess"
      data-qa="ops-brand-rename__success"
    >
      {{ successMessage }}
    </v-alert>
    <v-sheet class="pa-6 mt-4" data-qa="ops-brand-rename__wrapper">
      <v-row>
        <v-col>
          <h2 class="title" data-qa="ops-brand-rename__title">Rename Brand</h2>
          <p class="mt-2 mb-0" data-qa="ops-brand-rename__desc">
            <!-- eslint-disable-next-line -->
            Following this setup, allows you to change a brand name.
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <OpsSearchBrand
            :brands="allBrands"
            @brand-change="handleSelectedBrand"
            data-qa="ops-brand-rename__search-brands"
          />
        </v-col>
        <v-col>
          <v-text-field
            v-model="newName"
            label="New Brand Name"
            class="mx-4"
            data-qa="ops-brand-rename__new-name"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row v-if="hasSelectedBrand" data-qa="ops-brand-rename__confirmation-message">
        <v-col>
          <p>
            Rename <strong>{{ selectedBrand.name }}</strong> to <strong>{{ newName }}</strong>
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn
            color="secondary"
            @click="$router.go(-1)"
            data-qa="ops-brand-rename__button--cancel"
          >
            Cancel
          </v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn
            color="primary"
            @click="submitForm"
            :disabled="!hasAllData"
            data-qa="ops-brand-rename__button--rename"
          >
            Rename
          </v-btn>
        </v-col>
      </v-row>
    </v-sheet>
  </div>
</template>

<script>
import OpsSearchBrand from "@/components/OpsSearchBrand";
import { renameBrand } from "@/services/brands";

export default {
  name: "OpsBrandRename",
  components: {
    OpsSearchBrand
  },
  data: () => {
    return {
      selectedBrand: {},
      newName: "",
      successMessage: ""
    }
  },
  computed: {
    allBrands: function() {
      return this.$store.state.brands;
    },
    hasSelectedBrand: function() {
      return this.selectedBrand?.slug;
    },
    hasNewName: function() {
      return this.newName.length;
    },
    hasAllData: function() {
      return this.hasSelectedBrand && this.hasNewName;
    },
    hasSuccess: function() {
      return !!this.successMessage.length;
    }
  },
  mounted: async function() {
    this.$store.dispatch("setBrands")
  },
  methods: {
    handleSelectedBrand: function(oldBrand, newBrand) {
      this.selectedBrand = newBrand;
    },
    submitForm: async function() {
      try {
        await renameBrand(this.selectedBrand.slug, this.newName);

        this.successMessage = `Success! ${this.selectedBrand.name} renamed to ${this.newName}`;

        this.resetState();
      } catch (e) {
        this.$store.dispatch("setError", `There was an error renaming the brand. Error code: ${e.response.status}.`)
      }
    },
    resetState: function() {
      this.selectedBrand = {};
      this.newName = "";
    }
  }
}
</script>