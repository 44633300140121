import {
  OPS_SET_ERROR,
  OPS_SET_LOADING,
  OPS_SET_BRANDS
} from "./mutations";

import { getAllBrands } from "@/services/brands";

export function setError({ commit }, payload) {
  commit(OPS_SET_ERROR, payload);
}

export async function setBrands({ commit, state }, forcedRefresh = false) {
  if (
    (!state.brands.length || forcedRefresh) &&
    !state.loading?.brands
  ) {
    commit(OPS_SET_BRANDS, []); // Reset in case there is a list
    commit(OPS_SET_LOADING, { brands: true });

    const brands = await getAllBrands();
    commit(OPS_SET_BRANDS, brands);

    commit(OPS_SET_LOADING, {});
  }
}