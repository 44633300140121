<template>
  <div data-qa="ops-collections-create__wrapper">
    <v-sheet class="pa-6 mt-4">
      <v-row>
        <v-col>
          <h2 class="title" data-qa="ops-collections-create__title">
            Create a brand collection
          </h2>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col>
          <v-card class="mb-4 mt-4">
            <v-card-title class="text-h5"> Brand collection </v-card-title>
            <v-card-text data-qa="ops-merge__desc--source">
              Add the name and a description of the brand collection
            </v-card-text>
            <v-card-text data-qa="ops-merge__desc--source">
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="name"
                    label="Collection Name*"
                    outlined
                    clearable
                    data-qa="ops-collections-create__input--collection-name"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="desc"
                    label="Collection Description*"
                    outlined
                    clearable
                    data-qa="ops-collections-create__input--collection-desc"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card>
            <v-card>
              <v-card-title class="text-h5"> Brands </v-card-title>
              <v-card-text data-qa="ops-merge__desc--source">
                Select a list of brands to add to the collection
              </v-card-text>
              <v-card-text
                v-for="(item, key) in brands"
                :key="`search-source-${key}-${item.slug}`"
                :class="key === 0 ? '' : 'pt-0'"
              >
                <OpsSearchBrand
                  @brand-change="handleBrands"
                  :brands="allBrands"
                  data-qa="ops-collections-create__input--brands"
                />
              </v-card-text>
            </v-card>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn
            color="secondary"
            @click="$router.go(-1)"
            data-qa="ops-collections-create__button--cancel"
          >
            Cancel
          </v-btn>
        </v-col>
        <v-col cols="auto">
          <v-dialog v-model="dialog" width="500">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                :disabled="disabledSubmit"
                v-bind="attrs"
                v-on="on"
                data-qa="ops-collections-create__button--review"
              >
                Review
              </v-btn>
            </template>
            <v-card data-qa="ops-collections-create__dialog">
              <v-card-title class="text-h5 primary white--text">
                <template> Confirmation </template>
              </v-card-title>

              <v-divider></v-divider>

              <v-card-text class="mt-6" data-qa="ops-collections-create__recap">
                <p>
                  You are about to create a brand collection called:<strong>{{
                    name
                  }}</strong>
                </p>
                <p>
                  It will have the description:<strong>{{ desc }}</strong>
                </p>
                <p>The following brands will be assigned to the collection:</p>
                <ul>
                  <li
                    v-for="(item, key) in filteredBrands"
                    :key="`${key}-list-item`"
                    data-qa="ops-collections-create__recap--brands-list"
                  >
                    {{ item.name }} - ({{ item.slug }})
                  </li>
                </ul>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-btn
                  color="secondary"
                  @click="closeDialog"
                  data-qa="ops-collections-create__button--close"
                >
                  Cancel
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  @click="createBrandCollection"
                  data-qa="ops-collections-create__button--submit"
                >
                  Create
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-sheet>
  </div>
</template>

<script>
import OpsSearchBrand from "@/components/OpsSearchBrand";
import { getAllBrands } from "@/services/brands";
import { postCollection } from "@/services/collections";

export default {
  name: "OpsCollectionsCreate",
  components: {
    OpsSearchBrand,
  },
  data: () => {
    return {
      dialog: false,
      name: "",
      desc: "",
      brands: [{}],
      allBrands: [],
      postBrands: [],
    };
  },
  async mounted() {
    this.allBrands = await getAllBrands();
  },
  computed: {
    disabledSubmit: function () {
      return (
        !this.name.length || !this.desc.length || !(this.brands.length > 1)
      );
    },
    filteredBrands: function () {
      return this.brands.filter(
        (item, index) => this.brands.indexOf(item) === index && item.slug
      );
    },
  },
  methods: {
    resetState() {
      this.name = "";
      this.desc = "";
      this.brands = [{}];
    },
    closeDialog: function () {
      this.dialog = false;
    },
    generatePostBrands: function () {
      this.filteredBrands.forEach((item) => {
        this.postBrands.push(item.slug);
      });
    },
    createBrandCollection: async function () {
      this.generatePostBrands();
      let data = {
        name: this.name,
        description: this.desc,
        brands: this.postBrands,
      };

      try {
        let response = await postCollection(data);

        this.dialog = false;

        this.$router.push({
          name: "OpsCollectionItem",
          params: { id: response.identifier },
        });
        this.resetState();
      } catch (e) {
        this.$store.dispatch("setError", `There was an error creating the collection. Error code: ${e.response.status}.`)
      }
    },
    handleBrands: function (oldVal, newVal) {
      // no old value, but new value = add to array
      if (!oldVal && newVal) {
        this.brands.push(newVal);
      }

      // has old value and new value = replace in array
      if (oldVal && newVal) {
        let foundIndex = this.brands.findIndex((item) =>
          Object.keys(item).length ? item?.slug === oldVal?.slug : false
        );
        this.$set(this.brands, foundIndex, newVal);
      }

      // has old value but no new value = remove from array
      if (oldVal && !newVal) {
        let foundIndex = this.brands.findIndex((item) =>
          Object.keys(item).length ? item?.slug === oldVal?.slug : false
        );

        this.brands.splice(foundIndex, 1);
      }
    },
  },
};
</script>
