import { axios } from "@flytio/shared-library";
import { overrideAxios } from "@/services/axios-override";

export async function getBrandsWithAssociation() {
  let response = await overrideAxios(() =>
    axios.get("/restaurant-brand-associations/brands")
  );

  return response.data;
}

export async function createBrandWithAssociation(name = "", tenant = "", locations = []) {
  let postData = {
    name,
    tenant,
    associatedRestaurants: locations
  }

  let response = await overrideAxios(() =>
    axios.post("/restaurant-brand-associations/brands", postData)
  );

  return response;
}

export async function getRestaurantsByBrand(brandSlug) {
  let response = await overrideAxios(() =>
    axios.get(`/restaurant-brand-associations/brands/${brandSlug}`)
  );

  return response.data;
}

export async function addRestaurantsToBrand(brandSlug, locations) {
  let postData = locations;

  let response = await overrideAxios(() =>
    axios.post(`/restaurant-brand-associations/brands/${brandSlug}/restaurants`, postData)
  );

  return response;
}

export async function removeRestaurantFromBrand(brandSlug, restaurantID) {
  let response = await overrideAxios(() =>
    axios.delete(`/restaurant-brand-associations/brands/${brandSlug}/restaurants/${restaurantID}`)
  );

  return response;
}
