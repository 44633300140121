<template>
  <Loader :loading="loading">
    <v-alert
      type="info"
      data-qa="ops-brands-with-association__info"
    >
      This shows only the brands and restaurants that have been manually mapped to appear in Flyt Connect. No other brands or restaurants will appear here.
    </v-alert>
    <v-sheet
      class="pa-6 mt-4"
      data-qa="ops-brands-with-association__wrapper"
    >
      <v-row align="center" justify="space-between">
        <v-col cols="auto">
          <h2
            class="title"
            data-qa="ops-brands-with-association__title"
          >
            Brands
          </h2>
        </v-col>
        <v-col cols="auto">
          <v-btn
            color="primary"
            @click="clickCreateBrand"
            data-qa="ops-brands-with-association__button--create-brand"
          >
            Create brand
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :headers="headers"
            :items="items"
            :items-per-page="10"
            :search="search"
            class="elevation-1"
            data-qa="ops-brands-with-association__table"
          >
            <template v-slot:top>
              <v-text-field
                v-model="search"
                label="Search"
                class="mx-4"
                data-qa="ops-brands-with-association__table-search"
              ></v-text-field>
            </template>
            <template v-slot:[`item.name`]="{ item }">
              <router-link
                data-qa="ops-brands-with-association__table-link"
                :to="{ name: 'OpsBrandsWithAssociationItem', params: { brandSlug: getRestaurantSlug(item.slug) } }"
              >
                {{ item.name }}
              </router-link>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-sheet>
    <ops-dialog-stepper
      :steps="createBrandSteps"
      :toggleDialog="createBrandDialog"
      title="Create a brand"
      close-button-text="Cancel"
      next-button-text="Confirm"
      :save-button-text="finalStepperButtonText"
      :disabled-button="isDisabledButton"
      :isSuccess="createBrandSuccess"
      :isError="createBrandError"
      @close="closeDialog"
      @save="createBrand"
      @refresh="refreshCreateBrandModel"
      data-qa="ops-brands-with-association__dialog"
      v-show="createBrandDialog"
    >
      <template #content1>
        <form>
          <v-row>
            <v-col>
              <v-text-field
                id="create-brand-name"
                name="create-brand-name"
                type="text"
                :required="true"
                label="Brand name *required"
                v-model="createBrandModel.name"
                hide-details="auto"
                outlined
                data-qa="ops-brands-with-association__field--brand-name"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                id="create-brand-tenant"
                name="create-brand-tenant"
                type="text"
                :required="true"
                label="Tenant *required"
                v-model="createBrandModel.tenant"
                hide-details="auto"
                :items="tenantList"
                outlined
                data-qa="ops-brands-with-association__field--tenant"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-alert
                type="info"
                outlined
              >
                For Skip brands, you must use the <strong>Restaurant UUID</strong> instead of the location ID.
              </v-alert>
              <v-textarea
                name="create-brand-locations"
                label="Paste location ID's here (1 location per line) *required"
                v-model="createBrandModel.locations"
                hide-details="auto"
                outlined
                data-qa="ops-brands-with-association__field--locations"
              ></v-textarea>
            </v-col>
          </v-row>
        </form>
      </template>
      <template #content2>
        <v-row>
          <v-col>
            You are about to create a new brand
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <ul data-qa="ops-brands-with-association__recap">
              <li data-qa="ops-brands-with-association__recap--brand-name">Brand name: <strong>{{ createBrandModel.name }}</strong></li>
              <li data-qa="ops-brands-with-association__recap--tenant">Tenant: <strong>{{ createBrandModel.tenant }}</strong></li>
              <li>Locations mapped to the brand:
                <ul>
                  <li
                    v-for="location in createBrandLocationList"
                    :key="`list-item-${location}`"
                    data-qa="ops-brands-with-association__recap--locations"
                  >
                    <strong>{{location}}</strong>
                  </li>
                </ul>
              </li>
            </ul>
          </v-col>
        </v-row>
      </template>
      <template #contentsuccess>
        <v-row align="center" justify="center" data-qa="ops-brands-with-association__success">
          <v-col cols="auto">
            <v-icon
              color="green"
              x-large
            >
              mdi-check-circle
            </v-icon>
          </v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-col cols="auto">
            <p class="mb-0">You successfully created a new brand</p>
          </v-col>
        </v-row>
      </template>
      <template #contenterror>
        <v-row align="center" justify="center" data-qa="ops-brands-with-association__error">
          <v-col cols="auto">
            <v-icon
              color="red"
              x-large
            >
               mdi-close-circle
            </v-icon>
          </v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-col cols="auto">
            <p class="mb-0">New brand creation failed</p>
            <p>Error message: <strong>{{ errorMessage }}</strong></p>
          </v-col>
        </v-row>
      </template>
    </ops-dialog-stepper>
  </Loader>
</template>

<script>
import Loader from "@flytio/shared-library/components/Loader";
import OpsDialogStepper from "@/components/OpsDialogStepper";

import { getBrandsWithAssociation, createBrandWithAssociation } from "@/services/restaurant-brand-associations";

export default {
  name: "OpsBrandsWithAssociation",
  components: {
    Loader,
    OpsDialogStepper
  },
  data: () => {
    return {
      loading: true,
      headers: [
        {
          text: 'Name', value: 'name'
        },
        {
          text: 'Brand Slug', value: 'slug'
        }
      ],
      items: [],
      search: "",
      createBrandDialog: false,
      createBrandSteps: [
        { id: 1 },
        { id: 2 }
      ],
      createBrandModel: {
        name: "",
        tenant: "",
        locations: ""
      },
      finalStepperButtonText: "Create",
      createBrandSuccess: false,
      createBrandError: false,
      tenantList: [
        "EN:AU",
        "DE:UK",
        "JE:CA",
        "JE:CH",
        "JE:DK",
        "JE:ES",
        "JE:FR",
        "JE:IE",
        "JE:IT",
        "JE:MX",
        "JE:NO",
        "JE:UK",
        "ML:AU",
        "ML:NZ",
        "SK:CA",
        "SK:US",
        "TA:AT",
        "TA:BE",
        "TA:BG",
        "TA:CH",
        "TA:DE",
        "TA:DK",
        "TA:FR",
        "TA:NL",
        "TA:NO",
        "TA:PL",
        "TA:PT",
        "TA:RO",
        "UE:UK"
      ],
      errorMessage: ""
    }
  },
  computed: {
    isDisabledButton: function() {
      return !(this.createBrandModel.name.length && this.createBrandModel.tenant.length && this.createBrandModel.locations.length)
    },
    createBrandLocationList: function() {
      return this.createBrandModel.locations.split(/\n/);
    }
  },
  mounted: async function() {
    await this.fetchItems();
    this.loading = false;
  },
  destroyed: function() {
    this.refreshCreateBrandModel();
  },
  methods: {
    fetchItems: async function() {
      let items = await getBrandsWithAssociation();
      this.items = items.length ? items : []; // items can be null, pass empty array if this is the case.
    },
    clickCreateBrand: function() {
      this.createBrandDialog = true;
    },
    closeDialog: async function() {
      await this.fetchItems();
      this.refreshCreateBrandModel();
    },
    createBrand: async function() {
      try {
        let response = await createBrandWithAssociation(
          this.createBrandModel.name,
          this.createBrandModel.tenant,
          this.createBrandLocationList
        );

        if (response.status !== 201) {
          this.createBrandSuccess = false;
          this.createBrandError = true;
        } else {
          this.createBrandSuccess = true;
          this.createBrandError = false;
        }
      } catch (e) {
        this.createBrandError = true;
        this.createBrandSuccess = false;

        if (e.message.length) {
          this.errorMessage = e.message;
        }
      }

      this.finalStepperButtonText = "Done";
    },
    refreshCreateBrandModel: function() {
      this.createBrandDialog = false;
      this.createBrandModel = {
        name: "",
        tenant: "",
        locations: ""
      }
      this.createBrandSuccess = false;
      this.createBrandError = false;
      this.finalStepperButtonText = "Create";
      this.errorMessage = "";
    },
    getRestaurantSlug: function(slug) {
      return this.items.find(item => item.slug === slug).slug
    }
  }
}
</script>
