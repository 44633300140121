import { axios } from "@flytio/shared-library";
import { token } from "@flytio/shared-library";

export async function overrideAxios(axiosCall = () => {}) {
  let headers = {
    Authorization: `Bearer ${token}`,
  };

  let response;
  const oldBaseUrl = axios.defaults.baseURL; // temporary until we find a better solution, or remove dependencies on flyt-connect-backend
  const oldHeaders = axios.defaults.headers;

  axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URI_BRAND_MANAGEMENT;
  axios.defaults.headers.common = headers;

  response = await axiosCall();

  axios.defaults.baseURL = oldBaseUrl; // temporary until we find a better solution, or remove dependencies on flyt-connect-backend
  axios.defaults.headers = oldHeaders;

  return response;
}
