<template>
  <div>
    <v-alert
      class="mt-2"
      :color="alertColor"
      :type="alertType"
      v-if="hasMessage"
      dismissible
      data-qa="ops-brand-merging__alert"
    >
      {{ alertMessage }}
    </v-alert>
    <v-sheet class="pa-6 mt-4" data-qa="ops-brand-merging__wrapper">
      <v-row>
        <v-col>
          <h2 class="title" data-qa="ops-brand-merging__title">Merge Brands</h2>
          <p class="mt-2 mb-0" data-qa="ops-brand-merging__desc">
            <!-- eslint-disable-next-line -->
            Following this setup allows you to merge multiple brands together by searching for a brand slug.
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <OpsMerge
            @error="addError"
            @success="addSuccess"
            data-qa="ops-brand-merging__merge-component"
          />
        </v-col>
      </v-row>
    </v-sheet>
  </div>
</template>

<script>
import OpsMerge from "@/components/OpsMerge";

export default {
  name: "OpsBrandMerging",
  components: {
    OpsMerge,
  },
  data: () => {
    return {
      alertType: "",
      alertMessage: "",
    };
  },
  destroyed() {
    (this.alertType = ""), (this.alertMessage = "");
  },
  computed: {
    hasMessage: function () {
      return this.alertMessage.length && this.alertType.length;
    },
    alertColor: function () {
      return this.alertType === "error" ? "red" : "green";
    },
  },
  methods: {
    addError: function (errorMessage) {
      this.alertType = "error";
      this.alertMessage = errorMessage;
    },
    addSuccess: function (successMessage) {
      this.alertType = "success";
      this.alertMessage = successMessage;
    },
  },
};
</script>
