import Vue from "vue";
import VueRouter from "vue-router";

import App from "@/App.vue";
import store from "@/store";
import router from "@/router";

import Vuetify from "vuetify";
import { vuetify, initAuth0 } from "@flytio/shared-library";

import "vuetify/dist/vuetify.min.css";
import "@mdi/font/css/materialdesignicons.css";

async function init() {
  Vue.config.productionTip = false;

  await initAuth0(window.location.origin);

  Vue.use(VueRouter);

  Vue.use(Vuetify);

  new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
  }).$mount("#app");
}

init();
