<template>
  <v-row align="center" data-qa="ops-search__wrapper">
    <v-col v-if="model && model.logo_url" cols="auto">
      <v-img
        max-height="45"
        max-width="45"
        :src="model.logo_url"
        data-qa="ops-search__image"
      />
    </v-col>
    <v-col>
      <v-autocomplete
        v-model="model"
        :items="items"
        color="primary"
        hide-no-data
        hide-selected
        item-text="slug"
        :prepend-icon="model ? '' : 'mdi-magnify'"
        return-object
        clearable
        :loading="isLoading"
        data-qa="ops-search__autocomplete"
      ></v-autocomplete>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "OpsSearchBrand",
  data: () => {
    return {
      descriptionLimit: 60,
      model: null,
    };
  },
  props: {
    brands: {
      type: Array,
      required: false,
      default: () => [],
    },
    newState: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  watch: {
    model(newVal, oldVal) {
      this.$emit("brand-change", oldVal, newVal);
    },
    newState(newVal) {
      if (newVal) {
        this.model = null;
      }
    },
  },
  computed: {
    isLoading() {
      return !this.brands.length;
    },
    items() {
      return this.brands.map((entry) => {
        const name =
          entry.name.length > this.descriptionLimit
            ? entry.name.slice(0, this.descriptionLimit) + "..."
            : entry.name;

        return Object.assign({}, entry, { name });
      });
    },
  },
};
</script>
