<template>
  <div>
    <v-sheet
      class="pa-6 mt-4"
      data-qa="ops-home__wrapper"
      v-for="(feature, index) in features"
      :key="`list-${feature.id}`"
      :class="{
        'mt-4': index > 0
      }"
    >
      <v-row
        align="center"
        data-qa="ops-home__feature"
      >
        <v-col>
          <h2 class="title" data-qa="ops-home__title">
            {{ feature.name }}
          </h2>
          <p class="mt-2 mb-0" data-qa="ops-home__desc">
            {{ feature.desc }}
          </p>
        </v-col>
        <v-col cols="auto">
          <v-btn
            color="primary"
            :to="{ name: feature.routeName }"
            data-qa="ops-home__button"
            >{{ feature.buttonText }}</v-btn
          >
        </v-col>
      </v-row>
    </v-sheet>
  </div>
</template>

<script>
import ROUTES_LIST from "@/router/routes";

export default {
  name: "OpsHome",
  computed: {
    features: () => {
      return ROUTES_LIST;
    },
  },
};
</script>
