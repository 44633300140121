<template>
  <v-app data-qa="ops-app__wrapper">
    <v-app-bar v-if="isLoggedIn" app data-qa="ops-app__header--wrapper">
      <Header :show-search="false" title="Flyt Ops" data-qa="ops-app__header" />
    </v-app-bar>
    <v-navigation-drawer
      permanent
      absolute
      expand-on-hover
      v-if="isLoggedIn"
      class="pt-16"
      data-qa="ops-app__nav"
    >
      <v-list nav dense class="pt-4 pb-4">
        <v-list-group
          v-for="(item, index) in features"
          :key="`${item.name}-${index}`"
          :prepend-icon="item.icon"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title
                :data-qa="`ops-app__nav--element-activator-${index}`"
                >{{ item.name }}</v-list-item-title
              >
            </v-list-item-content>
          </template>
          <v-list-item
            link
            v-for="(child, index) in item.children"
            :key="`${child}-${child.name}-${index}`"
            :to="{ name: child.routeName }"
            active-class="highlighted"
            :class="child.routeName === $route.name ? 'highlighted' : ''"
            :data-qa="`ops-app__nav--element-${index}`"
          >
            <v-list-item-icon>
              <v-icon data-qa="ops-app__nav--icon">{{ child.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title data-qa="ops-app__nav--title">
              {{ child.name }}
            </v-list-item-title>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>
    <v-main class="grey lighten-3 pt-16 pl-16">
      <v-container class="fluid">
        <v-alert
          class="mb-0"
          dismissible
          type="error"
          v-if="hasErrorMessage"
          @input="clearError"
          v-model="alert"
          data-qa="ops-app__error"
        >
          {{ errorMessage }}
        </v-alert>
        <v-row align="center" justify="center">
          <v-col lg="12" md="12" sm="12">
            <router-view data-qa="ops-app__views" />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Header from "@flytio/shared-library/components/Header";
import {
  user,
  isAuthenticated,
  token,
  configureAxios,
} from "@flytio/shared-library";

import ROUTES_LIST from "@/router/routes";

export default {
  name: "OpsApp",
  components: {
    Header,
  },
  data: () => {
    return {
      alert: true
    }
  },
  async mounted() {
    if (this.isLoggedIn) {
      await configureAxios(process.env.VUE_APP_BACKEND_URI, token);
    }
  },
  computed: {
    errorMessage: function() {
      return this.$store.state.errorMessage;
    },
    hasErrorMessage: function() {
      return this.errorMessage?.length
    },
    isLoggedIn: function() {
      return user?.email?.length && isAuthenticated && token;
    },
    features: function() {
      return ROUTES_LIST;
    },
  },
  methods: {
    clearError: function() {
      this.$store.dispatch("setError", "")
      this.alert = true; // We hide it when no error is shown, using the computed property, setting alert to true allows us to re-display it when needed
    }
  }
};
</script>

<style lang="scss">
@import "~@flytio/shared-library/styles/main.scss";

.highlighted {
  background-color: $color-blue;
  color: #fff !important;
}
</style>
