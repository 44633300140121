<template>
  <Loader :loading="loading">
    <v-alert
      type="info"
      data-qa="ops-brands-with-association-item__info-banner"
    >
      This shows only the restaurants that have been manually mapped to appear in Flyt Connect under the brand {{ data.name }}. No other restaurants will appear here.
    </v-alert>
    <v-sheet
      class="pa-6 mt-4"
    >
      <v-row align="center" justify="space-between">
        <v-col cols="auto">
          <h2
            class="title"
            data-qa="ops-brands-with-association-item__title"
          >
            {{ data.name }} - Location mappings
          </h2>
        </v-col>
        <v-col cols="auto">
          <v-btn
            color="primary"
            @click="openAssignDialog"
            data-qa="ops-brands-with-association-item__button--assign-locations"
          >
            Assign locations
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :headers="headers"
            :items="data.associatedRestaurants"
            :items-per-page="10"
            :search="search"
            class="elevation-1"
            data-qa="ops-brands-with-association-item__table"
          >
            <template v-slot:top>
              <v-text-field
                v-model="search"
                label="Search"
                class="mx-4"
                data-qa="ops-brands-with-association-item__search"
              ></v-text-field>
            </template>
            <template v-slot:[`item.remove`]="{ item }">
              <v-btn
                @click="openDeleteDialog(item.id)"
                color="error"
                class="pa-0"
                plain
                data-qa="ops-brands-with-association-item__button--remove"
              >
                Remove
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-sheet>
    <ops-dialog-stepper
      :steps="assignSteps"
      :toggleDialog="assignDialog"
      title="Assign locations"
      close-button-text="Cancel"
      next-button-text="Confirm"
      save-button-text="Save"
      :disabled-button="isDisabledAssignButton"
      :isSuccess="assignSuccess"
      :isError="assignError"
      @close="closeAssignDialog"
      @save="confirmAssign"
      @refresh="fetchLocations"
      v-show="assignDialog"
      data-qa="ops-brands-with-association-item__dialog--assign"
    >
      <template #content1>
         <form>
          <v-row>
            <v-col>
              <v-alert
                type="info"
                outlined
                data-qa="ops-brands-with-association-item__banner--info-skip"
              >
                For Skip brands, you must use the <strong>Restaurant UUID</strong> instead of the location ID.
              </v-alert>
              <v-textarea
                name="create-brand-locations"
                label="Paste location ID's here (1 location per line) *required"
                v-model="assignRestaurants"
                hide-details="auto"
                outlined
                data-qa="ops-brands-with-association-item__input--assign-locations"
              ></v-textarea>
            </v-col>
          </v-row>
        </form>
      </template>
      <template #content2>
        <v-row>
          <v-col data-qa="ops-brands-with-association-item__recap--brand-name">
            <p>You are about to assign new locations to <strong>{{ data.name }}</strong></p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <ul data-qa="ops-brands-with-association__recap">
              <li>Locations mapped to the brand:
                <ul>
                  <li
                    v-for="location in assignRestaurantsList"
                    :key="`list-item-${location}`"
                    data-qa="ops-brands-with-association-item__recap--locations"
                  >
                    <strong>{{location}}</strong>
                  </li>
                </ul>
              </li>
            </ul>
          </v-col>
        </v-row>
      </template>
      <template #contentsuccess>
        <v-row align="center" justify="center" data-qa="ops-brands-with-association-item__assign-success">
          <v-col cols="auto">
            <v-icon
              color="green"
              x-large
            >
              mdi-check-circle
            </v-icon>
          </v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-col cols="auto">
            <p class="mb-0">You successfully assigned new locations</p>
          </v-col>
        </v-row>
      </template>
      <template #contenterror>
        <v-row align="center" justify="center" data-qa="ops-brands-with-association-item__assign-error">
          <v-col cols="auto">
            <v-icon
              color="red"
              x-large
            >
               mdi-close-circle
            </v-icon>
          </v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-col cols="auto">
            <p class="mb-0">Assigning locations failed</p>
          </v-col>
        </v-row>
      </template>
    </ops-dialog-stepper>
    <v-dialog
      v-model="deleteDialog"
      v-show="deleteDialog"
      width="600px"
      data-qa="ops-brands-with-association-item__dialog--remove"
    >
      <v-card>
        <v-card-title class="headline mb-4">
          <h2 class="title">
            Remove location?
          </h2>
        </v-card-title>
        <v-card-text v-if="!deleteSuccess && !deleteError" data-qa="ops-brands-with-association-item__remove-recap">
          <p>You are about to remove the following association from <strong>{{ data.name }}</strong>:</p>
          <ul>
            <li>
              <strong>{{ deleteAssociationId }}</strong>
            </li>
          </ul>
          <p class="mt-4">You can add this location back in again.</p>
        </v-card-text>
        <v-card-text v-else-if="deleteSuccess">
          <v-row align="center" justify="center" data-qa="ops-brands-with-association-item__remove-recap--succes">
            <v-col cols="auto">
              <v-icon
                color="green"
                x-large
              >
                mdi-check-circle
              </v-icon>
            </v-col>
          </v-row>
          <v-row align="center" justify="center">
            <v-col cols="auto">
              <p class="mb-0">You successfully removed the association</p>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text v-else-if="deleteError">
          <v-row align="center" justify="center" data-qa="ops-brands-with-association-item__remove-recap--error">
            <v-col cols="auto">
              <v-icon
                color="red"
                x-large
              >
                mdi-close-circle
              </v-icon>
            </v-col>
          </v-row>
          <v-row align="center" justify="center">
            <v-col cols="auto">
              <p class="mb-0">Removing association failed</p>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-container>
            <v-row no-gutters>
              <v-col>
                <v-divider class="mb-4"></v-divider>
              </v-col>
            </v-row>
            <v-row class="mt-0" align="center" justify="end">
              <v-col cols="auto">
                <v-btn
                  color="primary"
                  outlined
                  @click="closeDeleteDialog"
                  data-qa="ops-brands-with-association-item__button--cancel-remove"
                >
                  Cancel
                </v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn
                  :color="deleteConfirmButtonColor"
                  @click="confirmDelete"
                  data-qa="ops-brands-with-association-item__button--confirm-remove"
                >
                  {{ deleteConfirmButtonText }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </loader>
</template>

<script>
import Loader from "@flytio/shared-library/components/Loader";
import OpsDialogStepper from "@/components/OpsDialogStepper";

import { getRestaurantsByBrand, removeRestaurantFromBrand, addRestaurantsToBrand } from "@/services/restaurant-brand-associations";

export default {
  name: "OpsBrandsWithAssociationItem",
  components: {
    Loader,
    OpsDialogStepper
  },
  data: () => {
    return {
      loading: true,
      headers: [
        {
          text: 'Restaurant ID', value: 'id'
        },
        {
          text: "Name", value: "name"
        },
        {
          text: "Last Associated", value: "lastAssociated"
        },
        {
          text: "Remove", value: "remove", align: 'end'
        }
      ],
      search: "",
      brandSlug: "",
      data: [],
      deleteDialog: false,
      deleteItemIndex: 0,
      deleteError: false,
      deleteSuccess: false,
      assignSteps: [
        { id: 1 },
        { id: 2 }
      ],
      assignDialog: false,
      assignRestaurants: "",
      assignError: false,
      assignSuccess: false
    }
  },
  computed: {
    deleteAssociationId: function() {
      return this.data.associatedRestaurants?.length ? this.data.associatedRestaurants[this.deleteItemIndex].id : null;
    },
    deleteConfirmButtonText: function() {
      return this.deleteError || this.deleteSuccess ? "Done" : "Confirm"
    },
    deleteConfirmButtonColor: function() {
      return this.deleteError || this.deleteSuccess ? "primary" : "error"
    },
    assignConfirmButtonText: function() {
      return this.assignError || this.assignSuccess ? "Done" : "Confirm"
    },
    assignRestaurantsList: function() {
      return this.assignRestaurants.split(/\n/);
    },
    isDisabledAssignButton: function() {
      return !this.assignRestaurants.length;
    }
  },
  mounted: async function() {
    this.brandSlug = this.$router.currentRoute.params.brandSlug;
    await this.fetchLocations();
    this.loading = false;
  },
  methods: {
    fetchLocations: async function() {
      this.data = await getRestaurantsByBrand(this.brandSlug);
    },
    openAssignDialog: function() {
      this.assignDialog = true;
    },
    closeAssignDialog: function() {
      this.assignDialog = false;
      this.assignRestaurants = "",
      this.assignError = false;
      this.assignSuccess = false;
    },
    confirmAssign: async function() {
      if (this.assignError || this.assignSuccess) {
        await this.fetchLocations();
        this.closeAssignDialog();
        return
      }

      // Trigger assign
      try {
        let response = await addRestaurantsToBrand(this.brandSlug, this.assignRestaurantsList);

        if (response.status !== 200) {
          this.assignError = true;
          this.assignSuccess = false;
        } else {
          this.assignSuccess = true;
          this.assignError = false;
        }
      } catch(e) {
        this.assignSuccess = false;
        this.assignError = true;
      }
    },
    openDeleteDialog: function(id) {
      this.deleteDialog = true;
      this.deleteItemIndex = this.data.associatedRestaurants.findIndex(item => item.id === id);
    },
    closeDeleteDialog: function() {
      this.deleteDialog = false;
      this.deleteItemIndex = 0;
      this.deleteError = false;
      this.deleteSuccess = false;
    },
    confirmDelete: async function() {
      if (this.deleteSuccess || this.deleteError) {
        await this.fetchLocations();
        this.closeDeleteDialog();
        return
      }

      let response = await removeRestaurantFromBrand(this.brandSlug, this.deleteAssociationId);

      if (response.status !== 204) {
        this.deleteError = true;
        this.deleteSuccess = false;
      } else {
        this.deleteSuccess = true;
        this.deleteError = false;
      }
    }
  }
}
</script>