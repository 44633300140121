import Vue from "vue";
import Vuex from "vuex";

import { getDefaultState } from "./state";
import * as actions from "./actions";
import mutations from "./mutations";

const state = getDefaultState();

Vue.use(Vuex);

export default new Vuex.Store({
  state,
  mutations,
  actions,
  modules: {},
});
