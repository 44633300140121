<template>
  <v-sheet class="pa-6 mt-4" data-qa="ops-collections-item__wrapper">
    <Loader :loading="loading">
      <v-row align="center" justify="space-between">
        <v-col cols="auto" v-if="!loading && brand.brands && brand.brands[0].logo_url">
          <v-img
            width="55"
            :src="brand.brands[0].logo_url"
            data-qa="ops-collections-item__brand-image"
          />
        </v-col>
        <v-col>
          <h2 class="title" data-qa="ops-collections-item__title">
            {{ brand.name }}
          </h2>
          <p>
            {{ brand.description }}
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :headers="headers"
            :items="brand.brands"
            item-key="name"
            :search="search"
            hide-default-footer
            class="elevation-1"
            data-qa="ops-collections-item__table"
          >
            <template v-slot:top>
              <v-text-field
                v-model="search"
                label="Search name"
                class="mx-4"
                data-qa="ops-collections-item__table--search"
              ></v-text-field>
            </template>
            <template v-slot:[`item.logo_url`]="{ value }">
              <v-img
                width="30"
                :src="value"
                data-qa="ops-collections-item__table--image"
              />
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </Loader>
  </v-sheet>
</template>

<script>
import Loader from "@flytio/shared-library/components/Loader";

import { getCollection } from "@/services/collections";

export default {
  name: "OpsCollectionsItem",
  components: {
    Loader,
  },
  data: () => {
    return {
      loading: true,
      brand: {},
      search: "",
      headers: [
        { value: "logo_url" },
        { text: "Name", value: "name" },
        { text: "ID", value: "identifier" },
        { text: "Restaurants", value: "restaurantCount" },
      ],
    };
  },
  mounted: async function () {
    this.loading = true;

    try {
      this.brand = await getCollection(this.$route.params.id);
    } catch (e) {
      this.$store.dispatch("setError", `There was an error getting the brand collection. Error code: ${e.response.status}.`)
    }

    this.loading = false;
  },
};
</script>
