const ROUTES_LIST = [
  {
    id: "brand-management",
    name: "Manage Brands",
    icon: "mdi-text-box-multiple",
    desc: "Manage brands and their collections. Create, merge, delete and rename brands within Flyt Connect to maintain operational efficiency for restaurants and brands.",
    routeName: "OpsBrandManagement",
    buttonText: "Manage",
    children: [
      {
        id: "brand-merge",
        desc: "Merge two or more brands together in Flyt Connect, when you merge brands the original brand is deleted.",
        icon: "mdi-merge",
        name: "Merge Brands",
        buttonText: "Merge Brands",
        routeName: "OpsBrandMerging",
      },
      {
        id: "brand-rename",
        desc: "Rename a Flyt Connect brand.",
        icon: "mdi-rename-box",
        name: "Rename Brand",
        routeName: "OpsBrandRename",
        buttonText: "Rename",
      },
      {
        id: "brands-with-association",
        desc: "View, Create and manage brands with associated restaurants",
        icon: "mdi-sitemap",
        name: "View & Create Brands",
        routeName: "OpsBrandsWithAssociation",
        buttonText: "View"
      }
    ],
  },
  {
    id: "collection-management",
    name: "Manage Collections",
    icon: "mdi-widgets",
    desc: "Manage brand collections. Create, merge, delete and rename brand collections within Flyt Connect.",
    routeName: "OpsCollectionManagement",
    buttonText: "Manage",
    children: [
      {
        id: "brand-collection-view",
        desc: "See all brand collections within Flyt Connect.",
        icon: "mdi-view-agenda",
        name: "View brand collections",
        routeName: "OpsBrandCollectionList",
        buttonText: "View",
      },
      {
        id: "brand-collection-create",
        desc: "Create new brand collections within Flyt Connect.",
        icon: "mdi-pencil",
        name: "Create brand collection",
        routeName: "OpsBrandCollectionCreate",
        buttonText: "Create",
      },
    ]
  }
];

export default ROUTES_LIST;
