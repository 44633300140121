<template>
  <v-sheet class="pa-6 mt-4" data-qa="ops-collections-list__wrapper">
    <v-row>
      <v-col>
        <h2 class="title" data-qa="ops-collections-list__title">
          Brand Collections
        </h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <Loader :loading="loading" data-qa="ops-collections-list__loader">
          <v-data-table
            :headers="headers"
            :items="brands"
            item-key="name"
            :search="search"
            hide-default-footer
            class="elevation-1"
            data-qa="ops-collections-list__table"
          >
            <template v-slot:top>
              <v-text-field
                v-model="search"
                label="Search name"
                class="mx-4"
                data-qa="ops-collections-list__table--search"
              ></v-text-field>
            </template>
            <template v-slot:[`item.identifier`]="{ value }">
              <router-link
                :to="{ name: 'OpsCollectionItem', params: { id: value } }"
              >
                {{ value }}
              </router-link>
            </template>
          </v-data-table>
        </Loader>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import Loader from "@flytio/shared-library/components/Loader";

import { getAllCollections } from "@/services/collections";

export default {
  name: "OpsCollectionsList",
  components: {
    Loader,
  },
  data: () => {
    return {
      search: "",
      headers: [
        { text: "ID", value: "identifier" },
        { text: "Name", value: "name" },
        { text: "Description", value: "description" },
        { text: "Brands", value: "brandCount" },
      ],
      loading: true,
      brands: [],
    };
  },
  async mounted() {
    this.loading = true;

    try {
      this.brands = await getAllCollections();
    } catch (e) {
      this.$store.dispatch("setError", `There was an error getting brand collections. Error code: ${e.response.status}.`)
    }

    this.loading = false;
  },
};
</script>
