import { axios } from "@flytio/shared-library";
import { overrideAxios } from "@/services/axios-override";

export async function getAllCollections() {
  let response = await overrideAxios(() => axios.get("/collections/brand"));

  return response.data;
}

export async function getCollection(brandCollectionSlug) {
  let response = await overrideAxios(() => axios.get(`/collections/brand/${brandCollectionSlug}`));

  return response.data;
}

export async function postCollection(data = {}) {
  if (!data.name?.length || !data.description?.length || !data.brands?.length) {
    return {
      localError: true,
    };
  }

  let response = await overrideAxios(() =>
    axios.post(`/collections/brand`, data)
  );

  return response.data;
}
