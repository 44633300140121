<template>
  <div data-qa="ops-brand-management__wrapper">
    <v-sheet
      class="pa-6 mt-4"
      v-for="feature in features"
      :key="`list-${feature.id}`"
      data-qa="ops-brand-management__item"
    >
      <v-row align="center" data-qa="ops-home__feature">
        <v-col>
          <h2
            class="title"
            :data-qa="`ops-brand-management__${feature.id}--title`"
          >
            {{ feature.name }}
          </h2>
          <p
            class="mt-2 mb-0"
            :data-qa="`ops-brand-management__${feature.id}--desc`"
          >
            {{ feature.desc }}
          </p>
        </v-col>
        <v-col cols="auto">
          <v-btn
            color="primary"
            :to="{ name: feature.routeName }"
            :data-qa="`ops-brand-management__${feature.id}--button`"
            >{{ feature.buttonText }}</v-btn
          >
        </v-col>
      </v-row>
    </v-sheet>
  </div>
</template>

<script>
import ROUTES_LIST from "@/router/routes";

export default {
  name: "OpsBrandManagement",
  computed: {
    features: () => {
      return ROUTES_LIST.find((item) => item.id === "brand-management")
        .children;
    },
  },
};
</script>
