import VueRouter from "vue-router";

import OpsHome from "@/views/OpsHome";
import OpsBrandManagement from "@/views/OpsBrandManagement";
import OpsCollectionManagement from "@/views/OpsCollectionManagement";

import OpsBrandMerging from "@/views/brands/OpsBrandMerging";
import OpsBrandRename from "@/views/brands/OpsBrandRename";
import OpsBrandsWithAssociation from "@/views/brands/OpsBrandsWithAssociation";
import OpsBrandsWithAssociationItem from "@/views/brands/OpsBrandsWithAssociationItem";
import OpsCollectionsCreate from "@/views/collections/OpsCollectionsCreate";
import OpsCollectionsList from "@/views/collections/OpsCollectionsList";
import OpsCollectionsItem from "@/views/collections/OpsCollectionsItem";


import Login from "@flytio/shared-library/components/Login";
import { AuthGuard } from "@flytio/shared-library";

function generateAuthGuard(to, from, next) {
  return AuthGuard(to, from, next, "/", "Login");
}

const routes = [
  {
    path: "/",
    name: "AppHome",
    component: OpsHome,
    beforeEnter: generateAuthGuard,
  },
  {
    path: "/login",
    name: "OpsLogin",
    component: Login,
    props: {
      loginTitle: "Flyt Ops",
    },
  },
  {
    path: "/brand-management",
    name: "OpsBrandManagement",
    component: OpsBrandManagement,
    beforeEnter: generateAuthGuard
  },
  {
    path: "/brand-management/merge",
    name: "OpsBrandMerging",
    component: OpsBrandMerging,
    beforeEnter: generateAuthGuard
  },
  {
    path: "/brand-management/rename",
    name: "OpsBrandRename",
    component: OpsBrandRename,
    beforeEnter: generateAuthGuard
  },
  {
    path: "/brand-management/brands-with-associations",
    name: "OpsBrandsWithAssociation",
    component: OpsBrandsWithAssociation,
    beforeEnter: generateAuthGuard
  },
  {
    path: "/brand-management/brands-with-associations/:brandSlug",
    name: "OpsBrandsWithAssociationItem",
    component: OpsBrandsWithAssociationItem,
    beforeEnter: generateAuthGuard
  },
  {
    path: "/collection-management",
    name: "OpsCollectionManagement",
    component: OpsCollectionManagement,
    beforeEnter: generateAuthGuard
  },
  {
    path: "/collection-management/create",
    name: "OpsBrandCollectionCreate",
    component: OpsCollectionsCreate,
    beforeEnter: generateAuthGuard
  },
  {
    path: "/collection-management/collections",
    name: "OpsBrandCollectionList",
    component: OpsCollectionsList,
    beforeEnter: generateAuthGuard
  },
  {
    path: "/collection-management/collections/:id",
    name: "OpsCollectionItem",
    component: OpsCollectionsItem,
    beforeEnter: generateAuthGuard
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
