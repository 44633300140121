export const OPS_SET_ERROR = "OPS_SET_ERROR";
export const OPS_SET_LOADING = "OPS_SET_LOADING";
export const OPS_SET_BRANDS = "OPS_SET_BRANDS";

let mutations = {
  [OPS_SET_ERROR](state, payload) {
    state.errorMessage = payload;
  },
  [OPS_SET_LOADING](state, payload) {
    state.loading = payload;
  },
  [OPS_SET_BRANDS](state, payload) {
    state.brands = payload;
  }
}

export default mutations;