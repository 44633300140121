<template>
  <div data-qa="ops-merge__wrapper">
    <v-row>
      <v-col cols="6">
        <v-card>
          <v-card-title class="text-h5" data-qa="ops-merge__title--source">
            Moving From
          </v-card-title>
          <v-card-text data-qa="ops-merge__desc--source">
            Merge these brands and its restaurants
          </v-card-text>
          <v-card-text
            v-for="(item, key) in sourceBrands"
            :key="`search-source-${key}`"
            :class="key === 0 ? '' : 'pt-0'"
          >
            <OpsSearchBrand
              @brand-change="handleSourceBrands"
              :brands="brands"
              :resetState="newState"
              data-qa="ops-merge__search--source"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card>
          <v-card-title class="text-h5" data-qa="ops-merge__title--dest">
            Moving To
          </v-card-title>
          <v-card-text data-qa="ops-merge__desc--dest">
            With this parent brand and its restaurants
          </v-card-text>
          <v-card-text>
            <OpsSearchBrand
              @brand-change="handleDestBrand"
              :brands="brands"
              :resetState="newState"
              data-qa="ops-merge__search--dest"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn
          color="secondary"
          @click="$router.go(-1)"
          data-qa="ops-merge__button--cancel"
        >
          Cancel
        </v-btn>
      </v-col>
      <v-col cols="auto">
        <v-dialog
          v-model="dialog"
          width="500"
          data-qa="ops-merge__confirm-dialog"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              :disabled="disabledSubmit"
              v-bind="attrs"
              v-on="on"
              data-qa="ops-merge__button--review"
            >
              Review
            </v-btn>
          </template>
          <v-card data-qa="ops-merge__confirm-dialog--content">
            <v-card-title
              class="text-h5 red white--text"
              data-qa="ops-merge__confirm-dialog--title"
            >
              <template v-if="hasErrors"> Error </template>
              <template v-else> Confirmation </template>
            </v-card-title>

            <v-card-text
              class="mt-6"
              v-if="hasErrors"
              data-qa="ops-merge__confirm-dialog--text-invalid"
            >
              There seems to be an issue with your selected brands. Please
              ensure there are no duplicates in source brands, and you are not
              trying to merge a same source and destinaton.
            </v-card-text>
            <v-card-text
              class="mt-6"
              v-else
              data-qa="ops-merge__confirm-dialog--text-valid"
            >
              Please verify the merging data below, as this action is
              <strong>irreversible</strong>. <br />
              <strong
                >Before merging, please verify brands are merged and restaurants
                correctly assigned on other sources</strong
              >
            </v-card-text>

            <v-divider></v-divider>

            <v-card-text
              class="mt-6"
              v-if="!hasErrors"
              data-qa="ops-merge__confirm-dialog--recap"
            >
              <strong>You are about to merge:</strong>
              <ul data-qa="ops-merge__confirm-dialog--recap-sources">
                <li
                  v-for="(item, key) in sourceBrandsWarning"
                  :key="`${key}-list-item`"
                >
                  {{ item.name }} - {{ item.source }} ({{ item.slug }})
                </li>
              </ul>
              <strong>Into:</strong>
              <ul data-qa="ops-merge__confirm-dialog--recap-dest">
                <li>
                  {{ destBrand.name }} - {{ destBrand.source }} ({{
                    destBrand.slug
                  }})
                </li>
              </ul>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-btn
                color="secondary"
                @click="closeDialog"
                data-qa="ops-merge__confirm-dialog--cancel-button"
              >
                Cancel
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                color="red"
                class="white--text"
                @click="submitMerge"
                :disabled="hasErrors"
                data-qa="ops-merge__confirm-dialog--merge-button"
              >
                Merge
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import OpsSearchBrand from "@/components/OpsSearchBrand";
import { mergeBrands } from "@/services/brands";

export default {
  name: "OpsMerge",
  components: {
    OpsSearchBrand,
  },
  data: () => {
    return {
      sourceBrands: [{}],
      destBrand: {},
      dialog: false,
      hasErrors: false,
      newState: false,
    };
  },
  destroyed() {
    // Refresh state just in case
    this.resetState();
  },
  async mounted() {
    this.$store.dispatch("setBrands")
    this.newState = false;
  },
  watch: {
    dialog: function (newVal) {
      if (newVal) {
        this.validateData();
      }
    },
  },
  computed: {
    brands: function() {
      return this.$store.state.brands;
    },
    disabledSubmit: function () {
      return !(
        this.sourceBrands.length >= 2 && Object.keys(this.destBrand).length
      );
    },
    sourceBrandsWarning: function () {
      return this.sourceBrands.filter(
        (element) => Object.entries(element).length
      );
    },
  },
  methods: {
    resetState: function () {
      this.sourceBrands = [{}];
      this.destBrand = {};
      this.dialog = false;
      this.hasErrors = false;
      this.newState = true;
    },
    handleSourceBrands: function (oldVal, newVal) {
      // no old value, but new value = add to array
      if (!oldVal && newVal) {
        this.sourceBrands.push(newVal);
        return;
      }

      // has old value and new value = replace in array
      if (oldVal && newVal) {
        let foundIndex = this.sourceBrands.findIndex((item) =>
          Object.keys(item).length ? item?.slug === oldVal?.slug : false
        );
        this.$set(this.sourceBrands, foundIndex, newVal);
        return;
      }

      // has old value but no new value = remove from array
      if (oldVal && !newVal) {
        let foundIndex = this.sourceBrands.findIndex((item) =>
          Object.keys(item).length ? item?.slug === oldVal?.slug : false
        );

        this.sourceBrands.splice(foundIndex, 1);
        return;
      }
    },
    handleDestBrand: function (oldVal, newVal) {
      if (!newVal) {
        this.destBrand = {};
      } else {
        this.destBrand = newVal;
      }
    },
    validateData: function () {
      let brandSourcesValueArr = this.sourceBrands.map(function (item) {
        return item.slug;
      });
      let hasDuplicateItemsSource = brandSourcesValueArr.some(function (
        item,
        idx
      ) {
        return brandSourcesValueArr.indexOf(item) != idx;
      });
      let hasSameSourceAndDest = this.sourceBrands.some(
        (element) => element.slug === this.destBrand.slug
      );

      if (hasDuplicateItemsSource || hasSameSourceAndDest) {
        this.hasErrors = true;
      }
    },
    closeDialog: function () {
      this.hasErrors = false;
      this.dialog = false;
    },
    submitMerge: async function () {
      // let response
      try {
        await mergeBrands(this.sourceBrands, this.destBrand);

        let successMessage = `All selected sources merged into ${this.destBrand.name} (${this.destBrand.slug})`;
        this.$emit("success", successMessage);

        this.resetState();
        this.$store.dispatch("setBrands", true)
      } catch (error) {
        let errorStatusCode = error.response.status;
        let errorMessage = error.response.data.message;

        this.$emit("error", `Error: ${errorStatusCode} - ${errorMessage}`);
      }

      this.dialog = false;
    },
  },
};
</script>
